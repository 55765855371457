import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider, createGlobalStyle } from 'styled-components';

import Seo from '../components/seo';
import Header from '../components/header';
import Transition from '../components/transition';
import Loader from '../components/loader';

import './layout.css';

const theme = {
    red: '#D01010',
    grey: ['#737373', '#4F4F4F', '#1B1B1B'],
    cubic: 'cubic-bezier(0.75, 0, 0.25, 1)',
    titleFont: 'Merriweather, serif',
};

const GlobalStyle = createGlobalStyle`
	body {
		background: #1B1B1B;
	}

    @keyframes dash {
        from {
            stroke-dashoffset: 1000;
        }
        to {
            stroke-dashoffset: 0;
        }
    }

`;

class Layout extends Component {
    state = {
        isLoading: true,
    };

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                isLoading: false,
            });
        }, 3500);
    }

    render() {
        return (
            <ThemeProvider theme={theme}>
                <>
                    <GlobalStyle />
                    <Seo title="Cardiff Based Illustrator and Designer" />
                    {this.state.isLoading ? (
                        <Loader />
                    ) : (
                        <>
                            <Header location={this.props.location} />
                            <Transition location={this.props.location}>{this.props.children}</Transition>
                        </>
                    )}
                </>
            </ThemeProvider>
        );
    }
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
