import React from 'react';
import styled from 'styled-components';

const Logo = styled.div`
    position: relative;

    #grey {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 0;

        g {
            stroke: #3a3a3a;
        }
    }

    #white {
        position: relative;
        z-index: 1;

        &:hover,
        &:focus {
            path {
                ${props => !props.hasLoaded
                    && `
                stroke-dasharray: 1000;
                stroke-dashoffset: 1000;
                animation: dash 3s linear forwards;
            `}
            }

            #l {
                animation-delay: 300ms;
            }
            #ar {
                animation-delay: 600ms;
            }
            #e {
                animation-delay: 900ms;
            }
            #t {
                animation-delay: 1300ms;
            }
            #t-top {
                animation-delay: 1500ms;
            }
            #rev {
                animation-delay: 1800ms;
            }
            #line {
                animation-delay: 2500ms;
            }
        }

        path {
            ${props => props.hasLoaded
                && `
                        stroke-dasharray: 1000;
                        stroke-dashoffset: 1000;
                        animation: dash 3s linear forwards;
                `}
        }

        #l {
            animation-delay: 300ms;
        }
        #ar {
            animation-delay: 600ms;
        }
        #e {
            animation-delay: 900ms;
        }
        #t {
            animation-delay: 1300ms;
        }
        #t-top {
            animation-delay: 1500ms;
        }
        #rev {
            animation-delay: 1800ms;
        }
        #line {
            animation-delay: 2500ms;
        }
    }

    @keyframes dash {
        to {
            stroke-dashoffset: 0;
        }
    }
`;

const logo = ({ hasLoaded, width, height }) => (
  <Logo hasLoaded={hasLoaded}>
    <svg
      id="white"
      width={width}
      height={height}
      viewBox="0 0 190 80"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Home"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g id="logo" stroke="#FFFFFF" strokeWidth="3">
          <path
            d="M89.357265,20.0086957 C89.357265,20.0086957 132.119658,17.6486957 149.264957,8.20869565"
            id="t-top"
          />
          <path d="M136.758974,0.923478261 L114.470085,48.7391304" id="t" />
          <path
            d="M128.993162,43.813913 L137.767521,24.5234783 C137.767521,24.5234783 138.675214,33.2452174 146.340171,27.3965217 C146.340171,27.3965217 133.531624,57.8713043 154.105983,29.9617391 C154.105983,29.9617391 164.494017,25.96 164.292308,21.24 C163.888889,14.5704348 146.945299,32.9373913 154.105983,38.1704348 C158.039316,41.0434783 170.747009,24.7286957 174.478632,18.2643478 L171.654701,35.2973913 L188.8,13.4417391"
            id="rev"
          />
          <g id="Group" transform="translate(0.000000, 13.339130)">
            <path
              d="M84.7179487,27.293913 C84.7179487,27.293913 95.1059829,23.2921739 94.9042735,18.5721739 C94.5008547,11.9026087 77.557265,30.2695652 84.7179487,35.5026087 C88.6512821,38.3756522 96.6188034,30.8852174 101.762393,24.0104348"
              id="e"
            />
            <path
              d="M25.0119658,21.1373913 C25.0119658,21.1373913 33.4837607,-1.84695652 14.6239316,16.4173913 C-4.23589744,34.6817391 -7.56410256,77.0591304 29.8529915,32.6295652"
              id="c"
            />
            <path
              d="M29.8529915,32.6295652 C29.8529915,32.6295652 50.4273504,15.8017391 50.4273504,2.66782609 C50.4273504,-5.23304348 34.391453,15.186087 28.7435897,36.8365217 C23.1965812,58.3843478 42.2581197,34.2713043 42.2581197,34.2713043"
              id="l"
            />
            <path
              d="M55.7726496,24.626087 C47.5025641,22.3686957 37.1145299,40.0173913 41.1487179,41.6591304 C47.5025641,44.1217391 56.2769231,26.1652174 56.2769231,26.1652174 C56.2769231,26.1652174 49.217094,39.093913 53.2512821,39.5043478 C57.1846154,39.9147826 60.1094017,35.1947826 60.1094017,35.1947826 L69.8923077,19.8034783 C69.8923077,19.8034783 69.791453,28.5252174 77.4564103,22.6765217 C77.4564103,22.6765217 62.8324786,49.3547826 83.3059829,29.7565217"
              id="ar"
            />
          </g>
          <path
            d="M74.5316239,74.9043478 C74.5316239,74.9043478 94.5008547,61.1547826 114.470085,62.2834783"
            id="line"
          />
        </g>
      </g>
    </svg>
    <svg
      id="grey"
      width={width}
      height={height}
      viewBox="0 0 190 80"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Home"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g id="logo" stroke="#FFFFFF" strokeWidth="3">
          <path
            d="M89.357265,20.0086957 C89.357265,20.0086957 132.119658,17.6486957 149.264957,8.20869565"
            id="t-top"
          />
          <path d="M136.758974,0.923478261 L114.470085,48.7391304" id="t" />
          <path
            d="M128.993162,43.813913 L137.767521,24.5234783 C137.767521,24.5234783 138.675214,33.2452174 146.340171,27.3965217 C146.340171,27.3965217 133.531624,57.8713043 154.105983,29.9617391 C154.105983,29.9617391 164.494017,25.96 164.292308,21.24 C163.888889,14.5704348 146.945299,32.9373913 154.105983,38.1704348 C158.039316,41.0434783 170.747009,24.7286957 174.478632,18.2643478 L171.654701,35.2973913 L188.8,13.4417391"
            id="rev"
          />
          <g id="Group" transform="translate(0.000000, 13.339130)">
            <path
              d="M84.7179487,27.293913 C84.7179487,27.293913 95.1059829,23.2921739 94.9042735,18.5721739 C94.5008547,11.9026087 77.557265,30.2695652 84.7179487,35.5026087 C88.6512821,38.3756522 96.6188034,30.8852174 101.762393,24.0104348"
              id="e"
            />
            <path
              d="M25.0119658,21.1373913 C25.0119658,21.1373913 33.4837607,-1.84695652 14.6239316,16.4173913 C-4.23589744,34.6817391 -7.56410256,77.0591304 29.8529915,32.6295652"
              id="c"
            />
            <path
              d="M29.8529915,32.6295652 C29.8529915,32.6295652 50.4273504,15.8017391 50.4273504,2.66782609 C50.4273504,-5.23304348 34.391453,15.186087 28.7435897,36.8365217 C23.1965812,58.3843478 42.2581197,34.2713043 42.2581197,34.2713043"
              id="l"
            />
            <path
              d="M55.7726496,24.626087 C47.5025641,22.3686957 37.1145299,40.0173913 41.1487179,41.6591304 C47.5025641,44.1217391 56.2769231,26.1652174 56.2769231,26.1652174 C56.2769231,26.1652174 49.217094,39.093913 53.2512821,39.5043478 C57.1846154,39.9147826 60.1094017,35.1947826 60.1094017,35.1947826 L69.8923077,19.8034783 C69.8923077,19.8034783 69.791453,28.5252174 77.4564103,22.6765217 C77.4564103,22.6765217 62.8324786,49.3547826 83.3059829,29.7565217"
              id="ar"
            />
          </g>
          <path
            d="M74.5316239,74.9043478 C74.5316239,74.9043478 94.5008547,61.1547826 114.470085,62.2834783"
            id="line"
          />
        </g>
      </g>
    </svg>
  </Logo>
);

export default logo;
