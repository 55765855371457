import React, { Component } from 'react';
import styled from 'styled-components';
import Logo from './logo';

const SiteLoader = styled.div`
    background: #1b1b1b;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;

    .siteloader-logo {
        opacity: ${props => (props.pageIn ? 1 : 0)};
        transform: ${props => (props.pageIn ? 'translateY(0)' : 'translateY(-30px)')};
        transition: all 500ms;
    }
`;

class Loader extends Component {
    state = {
        pageIn: true,
    };

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                pageIn: false,
            });
        }, 3000);
    }

    render() {
        return (
            <SiteLoader pageIn={this.state.pageIn}>
                <div className="siteloader-logo">
                    <Logo hasLoaded width="190px" height="76px" />
                </div>
            </SiteLoader>
        );
    }
}

export default Loader;
