import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import useWindowSize from '@rehooks/window-size';
import styled from 'styled-components';
import Logo from './logo';

const SiteHeader = styled.header`
    display: flex;
    padding: 20px 0 0 20px;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    z-index: 2;
    overflow: hidden;
    @media (min-width: 768px) {
        padding: 43px 0 0 37px;
    }

    .siteheader-logo {
        opacity: ${props => (props.pageIn ? 1 : 0)};
        transform: ${props => (props.pageIn ? 'translateY(0)' : 'translateY(-30px)')};
        transition: all 300ms;
    }

    .siteheader-link {
        color: white;
        font-weight: 700;
        transform: ${props => (props.pageIn ? 'translateX(-50px)' : 'translateX(0px)')};
        position: relative;
        line-height: 1;
        opacity: ${props => (props.pageIn ? 1 : 0)};
        transition: all 300ms;

				@media(min-width: 768px) {
					transform: ${props => (props.pageIn ? 'translateX(-100px)' : 'translateX(0px)')};
				}

        &::before,
        &::after {
					content: '';
					position: absolute;
					top: -1px;
					width: 50px;
					height: 1px;
					background: #fff;
					transition: color 1s cubic-bezier(0.19, 1, 0.22, 1), transform 0.7s cubic-bezier(0.19, 1, 0.22, 1), width 0.8s cubic-bezier(0.19, 1, 0.22, 1);
					@media(min-width: 768px) {
						width: 95px;
					}
        }

        &::after {
            left: 100%;
            background: white;
            transform: translate3d(155px, 8px, 0);
        }

        &::before {
            left: 100%;
            background: white;
            transform: translate3d(30%, 8px, 0);
        }

        &:hover {
            &::after {
              transform: translate3d(30px, 8px, 0);
            }

            &::before {
							width: 0vw;
							-webkit-transform: translate3d(-100px, 8px, 0);
							transform: translate3d(-100px, 8px, 0);
            }
        }
    }

    .siteheader-line {
        height: 1px;
        width: 30px;
        background: white;
        margin-left: 18px;
        transition: all 150ms ${props => props.theme.cubic};
        position: relative;
        transform: translateY(-2px);
        @media (min-width: 768px) {
            width: 94px;
        }
    }
`;

const header = ({ location }) => {
  const [pageIn, setPageIn] = useState(false);
  const { innerWidth } = useWindowSize();

  useEffect(() => {
    if (!pageIn) {
      setTimeout(() => {
        setPageIn(true);
      }, 300);
    }
  });

  return (
    <SiteHeader pageIn={pageIn}>
      <Link to="/">
        <div className="siteheader-logo">
          <Logo hasLoaded={false} width={innerWidth < 768 ? '98px' : '118px'} height={innerWidth < 768 ? '41.8px' : '61.8px'} />
        </div>
      </Link>
      <Link to={location.pathname === '/about' ? '/' : '/about'} className="siteheader-link">
        {location.pathname === '/about' ? 'Projects' : 'About'}
      </Link>
    </SiteHeader>
  );
};

export default header;
